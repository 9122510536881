import { ApolloClient, NormalizedCacheObject, useReactiveVar } from "@apollo/client"
import {
  SubscribeForPush,
  SubscribeForPushVariables,
} from "@app/globalStates/Notification/types/SubscribeForPush"
import { userGlobalState } from "@app/globalStates/User/userGlobalState"
import { initializeApollo } from "@temp/@next/staticProps/apolloClient"
import React, { useEffect } from "react"
import { PushSubscriptionTypeEnum } from "../../../../types/globalTypes"

import { pushNotificationMutation } from "./queries"

export const mutateNotification = async (body: SubscribeForPushVariables) => {
  const apolloClient: ApolloClient<NormalizedCacheObject> = initializeApollo()
  return apolloClient.mutate<SubscribeForPush>({
    mutation: pushNotificationMutation,
    variables: body,
  })
}

export const sentNotificationData = async (isNewNotificationData: boolean) => {
  if (
    window &&
    window.Notification &&
    Notification.permission === "granted" &&
    "serviceWorker" in navigator
  ) {
    const registration = await navigator.serviceWorker.getRegistration()
    if (registration?.pushManager) {
      const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey:
          "BHWBR9dLFv8I_F_Xnoa0snHqou2kL2wliYP4Feq1T6XruiJ0G1cTvZCkgVqnuKD7H2qaXh3njpSSOmOAVfMUWS4",
      })

      const request = subscription.toJSON()
      const { user } = userGlobalState()
      if (user) {
        const body: SubscribeForPushVariables = {
          endpoint: request.endpoint!,
          p256dh: request.keys!.p256dh,
          auth: request.keys!.auth,
          type: isNewNotificationData
            ? PushSubscriptionTypeEnum.ORDER
            : PushSubscriptionTypeEnum.UPDATE,
        }
        mutateNotification(body)
      }
    }
  }
}

export const NotificationState: React.FC = () => {
  const { user } = useReactiveVar(userGlobalState)
  useEffect(() => {
    if (user) {
      sentNotificationData(false)
    }
  }, [user])
  return <></>
}
