import React, { useEffect } from "react"
import { getMessaging, isSupported, onMessage } from "firebase/messaging"
import { firebaseCloudMessaging } from "@temp/core/webPush"
import { firstTick } from "@temp/localStates/documentComplete"
import { useReactiveVar } from "@apollo/client"
import { sentNotificationData } from "@temp/@next/globalStates/Notification/Notification"
import { firebaseConfig } from "@temp/constants"

const getMessage = (): void => {
  const messaging = getMessaging()
  onMessage(messaging, async (message) => {
    const { title, body } = JSON.parse(JSON.stringify(message.notification))
    const options = {
      body,
    }
    const registration = await navigator.serviceWorker.getRegistration(
      "/firebase-cloud-messaging-push-scope"
    )
    registration?.showNotification(title, options)
  })
}
// eslint-disable-next-line import/no-unused-modules
export const PushNotification: React.FC = () => {
  const load = useReactiveVar(firstTick)
  useEffect(() => {
    if ("serviceWorker" in navigator) {
      window.addEventListener("load", () => {
        navigator.serviceWorker
          .register(
            `${
              window.location.origin
            }/firebase-messaging-sw.js?firebaseConfig=${encodeURIComponent(
              JSON.stringify(firebaseConfig)
            )}`
          )
          .then((registration) => {
            console.log(
              "FCM Service Worker registration successful with scope:",
              registration.scope
            )
          })
          .catch((error) => {
            console.log("FCM Service Worker registration failed:", error)
          })
      })
    }
    isSupported()
      .then((response) => {
        if (response !== false) {
          const setToken = async (): Promise<void> => {
            try {
              const token = await firebaseCloudMessaging.init()
              if (token) {
                getMessage()
                sentNotificationData(false)
              }
            } catch (error) {
              console.log(error)
            }
          }
          if (load) {
            setToken()
          }
        }
      })
      .catch((error) => console.log(error))
  }, [load])
  return null
}
