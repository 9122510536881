import { gql } from "@apollo/client"

export const pushNotificationMutation = gql`
  mutation SubscribeForPush(
    $auth: String!
    $endpoint: String!
    $p256dh: String!
    $type: PushSubscriptionTypeEnum!
  ) {
    pushSubscribe(auth: $auth, endpoint: $endpoint, p256dh: $p256dh, type: $type) {
      pushSubscribe {
        id
      }
    }
  }
`
