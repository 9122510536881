import firebase from "firebase/compat/app"
import { firebaseConfig, vapidKey } from "@temp/constants"
import { getMessaging, getToken } from "firebase/messaging"

// eslint-disable-next-line import/no-extraneous-dependencies
import localforage from "localforage"

const firebaseCloudMessaging = {
  // checking whether token is available in indexed DB
  tokenInlocalforage: async () => localforage.getItem("fcm_token"),

  // initializing firebase app
  // eslint-disable-next-line consistent-return
  async init() {
    if (firebase.apps.length === 0) {
      firebase.initializeApp(firebaseConfig)
      try {
        const messaging = getMessaging()
        const tokenInLocalForage = await this.tokenInlocalforage()

        // if FCM token is already there just return the token
        if (tokenInLocalForage !== null) {
          return tokenInLocalForage
        }

        // requesting notification permission from browser
        const status = Notification.permission
        if (status && status === "granted") {
          // getting token from FCM
          const fcmToken = await getToken(messaging, { vapidKey })
          if (fcmToken) {
            // setting FCM token in indexed db using localforage
            localforage.setItem("fcm_token", fcmToken)
            // return the FCM token after saving it
            return fcmToken
          }
        }
      } catch (error) {
        console.error(error)
        return null
      }
    }
  },
}
export { firebaseCloudMessaging }
